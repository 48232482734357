import * as Sentry from '@sentry/vue'
import { getFileExtension } from '@/core/utils/shared'

import { mainInfoService } from '@/services/shared'
import { useSurveyStore } from '@/views/survey/survey.store'

import { type TNullable, type IMainInfo, type IMainInfoDocument, EFileType } from '@/types/shared'

export const useMainInfoStore = defineStore('mainInfoStore', () => {
  const mainInfo = ref<TNullable<IMainInfo>>(null)
  const surveyStore = useSurveyStore()

  const documents = computed(() => {
    const result: IMainInfoDocument[] = []
    if (mainInfo.value) {
      const generationDate = mainInfo.value.generation_time
        ? new Date(mainInfo.value.generation_time).toLocaleDateString('en-GB')
        : new Date().toLocaleDateString('en-GB')

      if (mainInfo.value.isk) {
        const extension = getFileExtension(mainInfo.value.isk) ?? 'pdf'
        const fileType = extension === 'pdf' ? EFileType.PDF : EFileType.DOC
        const fullName = surveyStore.form.step1
          ? {
            lastname: surveyStore.form.step1.lastname,
            name: surveyStore.form.step1.name[0].toUpperCase(),
            surname: surveyStore.form.step1.surname[0].toUpperCase()
          }
          : null

        const fileName = fullName
          ? `Заявление в суд ${fullName.lastname} ${fullName.name}. ${fullName.surname}.${extension}`
          : `Заявление в суд.${extension}`

        result.push({
          name: fileName,
          link: mainInfo.value.isk,
          fileType,
          extension,
          generationDate
        })
      }
      if (mainInfo.value.properties) {
        const extension = getFileExtension(mainInfo.value.properties) ?? 'pdf'
        const fileType = extension === 'pdf' ? EFileType.PDF : EFileType.DOC
        result.push({
          name: `Опись имущества.${extension}`,
          link: mainInfo.value.properties,
          fileType,
          extension,
          generationDate
        })
      }
      if (mainInfo.value.creditors) {
        const extension = getFileExtension(mainInfo.value.creditors) ?? 'pdf'
        const fileType = extension === 'pdf' ? EFileType.PDF : EFileType.DOC
        result.push({
          name: `Реестр кредиторов.${extension}`,
          link: mainInfo.value.creditors,
          fileType,
          extension,
          generationDate
        })
      }
      if (mainInfo.value.triggers_file) {
        const extension = getFileExtension(mainInfo.value.triggers_file) ?? 'pdf'
        const fileType = extension === 'pdf' ? EFileType.PDF : EFileType.DOC
        result.push({
          name: `Рекомендации по прохождению процедуры.${extension}`,
          link: mainInfo.value.triggers_file,
          fileType,
          extension,
          generationDate
        })
      }
    }
    return result
  })

  async function getMainInfo () {
    mainInfo.value = await mainInfoService.getMainInfo()
    Sentry.setUser({
      id: mainInfo.value.id,
      email: mainInfo.value.email,
      fullname: mainInfo.value.name,
      phone: mainInfo.value.phone
    })
  }

  return {
    mainInfo,
    documents,
    getMainInfo
  }
})
