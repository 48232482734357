import { http } from '@/http'
import type { IMainInfo } from '@/types/shared'

class MainInfoService {
  getMainInfo () {
    return http.get<IMainInfo>('/me/')
  }

  sendUserEvent (_eventInfo: Partial<IMainInfo>) {
    return http.post('/me/update/', _eventInfo)
  }
}
export const mainInfoService = new MainInfoService()
